@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.btn--primary {
    border-color: rgb(234 16 16/var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(234 16 16/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
}

.btn--outline-white, .btn--primary {
    border-width: 1px;
    --tw-border-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26/var(--tw-bg-opacity));
}